import React, { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import useStyles from './style';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getInvoiceCompany, putInvoiceCompany } from "../../redux/actions/parameters";
import BackupIcon from '@material-ui/icons/Backup';
import { fileToBase64 } from "../../../utils/io";

const textFields = [
    { name: "name", required: true },
    { name: "address", required: true },
    { name: "postalCode", required: true },
    { name: "city", required: true },
    { name: "country", required: true },
    { name: "siren", required: false },
    { name: "email", required: true },
    { name: "tvaNumber", required: false }
];

function CompanyInformation() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invoiceCompany = useSelector(({ parameters }) => parameters.invoiceCompany);
    const { register, handleSubmit, formState, reset } = useForm();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploadMessage, setFileUploadMessage] = useState(null);

    const onSubmit = (data) => {
        console.log(selectedFile)
        fileToBase64(selectedFile).then(
            file => {
                data.logo = file;
                data.logoFileName = selectedFile.name;
                console.log(data);
                dispatch(putInvoiceCompany(data));
            }
        );
    };


    // const handleFileUpload = async () => {
    //     if (!selectedFile) {
    //         setFileUploadMessage('Please select a file first');
    //         return;
    //     }

        
    //     try {
    //         const response = await axios.post('http://localhost:8080/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         setFileUploadMessage(response.data);
    //     } catch (error) {
    //         setFileUploadMessage('Error uploading file');
    //     }
    // }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    useEffect(() => {
        dispatch(getInvoiceCompany());
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        reset(invoiceCompany, { keepDirty: false });
    }, [invoiceCompany]); //eslint-disable-line react-hooks/exhaustive-deps

    console.log(invoiceCompany);
    return (

        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t('invoice_company')}</Typography>
                    </Grid>
                    {
                        textFields.map((field, idx) =>
                            <Grid item xs={(((textFields.length % 2) !== 0) && (textFields.length - 1 === idx)) ?  12 : 6} key={idx}>
                                <TextField
                                    label={t(field.name)}
                                    defaultValue=" "
                                    variant="outlined"
                                    fullWidth
                                    {...register(field.name, { required: field.required })}
                                    error={formState.errors[field.name]}
                                />
                            </Grid>
                        )
                    }
                    <Grid item xs={12}>
                        <TextField
                            label={t('bankingInfoBloc')}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("bankingInfoBloc", { required: false })}
                            error={formState.errors["bankingInfoBloc"]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('footerMessage')}
                            defaultValue=" "
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("footerMessage", { required: false })}
                            error={formState.errors["footerMessage"]}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <input
                            id="raised-button-file"
                            type="file"
                            accept="image/*"
                            onInput={handleFileSelect}
                            {...register("companyLogo",  {
                                required: false,
                                validate: {
                                    maxSize: files => files[0]?.size < 5000000 || 'Max 5MB',
                                    acceptedFormats: files => 
                                        ['image/jpeg', 'image/png', 'image/gif'].includes(files[0]?.type) || 'Only PNG, JPEG e GIF',
                                }
                            })}
                            hidden
                        />
                        <label htmlFor="raised-button-file">
                            <Button 
                                variant="contained" 
                                component="span"
                                startIcon={<BackupIcon />}
                            >
                                {t("select_company_logo")}
                            </Button>
                        </label>
                    </Grid>
                    {selectedFile && (
                        <Typography variant="body1" gutterBottom>
                            Selected file: {selectedFile.name}
                        </Typography>
                    )}
                    {/*<Grid item xs={12}>
                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<BackupIcon />}
                            style={{height: "35px", textTransform: "none"}}
                        >
                            {t("upload_stamp")}
                            <input
                                type="file"
                                onChange={() => {}}
                                {...register("stamp", {
                                    required: false,
                                    validate: {
                                        maxSize: files => files[0]?.size < 5000000 || 'Max 5MB',
                                        acceptedFormats: files => 
                                            ['image/jpeg', 'image/png', 'image/gif'].includes(files[0]?.type) || 'Only PNG, JPEG e GIF',
                                    } 
                                })}
                                hidden
                            />
                        </Button>
                    </Grid> */}
                    <Grid container item xs={12} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disableElevation
                            disabled={!formState.errors || !formState.isDirty}
                        >{t('save')}</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>

    );

}

export default CompanyInformation;