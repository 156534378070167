import { onError, onSuccess } from "../../../../utils/http"
import { DELETE_USER, GET_ALL_USERS, POST_USER, PUT_USER, SET_ALL_USERS } from "../../constants";
import { ACCOUNT_ENDPOINT, FINANCIAL_INVOICING_ENDPOINT } from "../../constants/endpoint";
import { ADMIN_INVOICING_ROLE, MANAGE_INVOICING_ROLE, READ_ONLY_INVOICING_ROLE } from "../../../../utils/constants";
import { errorNotification, successNotification } from "../../../../utils/notification";

export const getAllUsers = () => {
    return {
        type: GET_ALL_USERS,
        request: {
            url: `${FINANCIAL_INVOICING_ENDPOINT}/collaborators/responsables`,
            method: "get",
        },
        meta: {
            onSuccess: onSuccess(({response, store}) => {
                store.dispatch(setAllUsers(response.data))
                return response;
            })
        }
    }
}

export const deleteUser = (user) => {
    return {
        type: DELETE_USER,
        request: {
            url: `${ACCOUNT_ENDPOINT}/users/${user.id}`,
            method: 'delete'
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', 'delete_success');
                return response;
            }),
            onError: onError(({error}) => {
                errorNotification('', "delete_error");
                throw error;
            })
        }
    }
}


export const setAllUsers = (data) => {
    return {
        type: SET_ALL_USERS,
        payload: data
    };
}

export const postUser = (user) => {
    switch (user.role) {
        case "Admin":
            user.isAdmin = true;
            break;
        case "Manager":
            user.isManager = true;
            break;
        case "Read Only":
            user.isReadOnly = true;
            break;
        default:
            break;
    }
    return {
        type: POST_USER,
        request: {
            url: `${ACCOUNT_ENDPOINT}/users`,
            method: 'post',
            data: user
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    };
};

export const putUSer = (user) => {
    switch (user.role) {
        case "Admin":
            user.isAdmin = true;
            break;
        case "Manager":
            user.isManager = true;
            break;
        case "Read Only":
            user.isReadOnly = true;
            break;
        default:
            break;
    }
    return {
        type: PUT_USER,
        request: {
            url: `${ACCOUNT_ENDPOINT}/users/${user.id}`,
            method: 'put',
            data: user
        },
        meta: {
            onSuccess: onSuccess(({ response }) => {
                successNotification('', "create_success");
                return response;
            }),
            onError: onError(({ error }) => {
                errorNotification('', "create_error");
                throw error;
            })
        }
    }
}