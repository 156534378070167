import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../../redux/actions/users";
import UsersList from "../../components/UsersList";
import { useTranslation } from "react-i18next";

export default function Users() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch])

    return(
        <>
        <Grid container className={classes.container} direction="column">
          <Grid
            className={classes.header}
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography className={classes.title}>{t("users")}</Typography>
            </Grid>
          </Grid>
          <UsersList />
        </Grid>
        {/* {userDialogOpen && <UserDialog setOpen={setUserDialogOpen} />} */}
      </>
    );
}