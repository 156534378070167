import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from '@material-ui/core';
import ListColsHeader from '../../../App/components/ListColsHeader';
import AddIcon from '@material-ui/icons/Add';
import { usersColumns } from '../../pages/Users/columns'; // Assuming you have imported the usersColumns array
import useStyles from "./styles";
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { deleteUser, getAllUsers } from '../../redux/actions/users';
import NoData from '../../../App/components/NoData';
import UserCard from './UserCard';
import NotifModal from '../../../App/components/NotifModal';
import UserDialog from './UserDialog';


function UsersList() {
    const classes = useStyles();
    const { t } = useTranslation();
    const usersData = useSelector(({ users }) => users.users);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [warnModal, setWarnModal] = useState(null);
    const dispatch = useDispatch();
    const [userDialogConfig, setUserDialogConfig] = useState(null)

    useEffect(() => {
        setLoading(true);
        dispatch(getAllUsers()).then(res => setLoading(false));
    }, [dispatch]);

    const openEdit = useCallback((user) => {
        setUserDialogConfig({
            action: "edit",
            user: user
        })
        setDialogOpen(true);
    }, []);

    const handleAdd = () => {
        setUserDialogConfig({
            action: "add",
        });
        setDialogOpen(true);
    }

    const handleDelete = (user) => {
        setDeleteModal({
            open: true,
            type: "error",
            title: t('suppression'),
            body: <span>{t('confirm_delete_user', { userName: `${user.firstName} ${user.lastName}` })}</span>,
            showCloseButton: true,
            onChange: () => setDeleteModal(false),
            cancel: {
                title: t('cancel'),
                onClick: null
            },
            confirm: {
                title: t('confirm'),
                onClick: () => {
                    dispatch(deleteUser(user))
                    .then((res) => {
                        dispatch(getAllUsers());
                    }).then((res) => {
                        setDeleteModal({ ...deleteModal, open: false })
                        if (!res) {
                            return;
                        }
                        setWarnModal({
                            open: true,
                            type: "warning",
                            title: t('suppression'),
                            body: <span>{ t("warn_cannot_delete_user") }</span>,
                            showCloseButton: false,
                            onchange: () => setWarnModal(false),
                            cancel: null,
                            confirm: {
                                title: t('ok'),
                                onClick: () => {
                                    setWarnModal(null);
                                }
                            }
                        })
                    });
                }
            }
        });
    }

    if (loading) {
        return <h2>Loading ...</h2>
    }

    return (
        <>
            <Grid container>
                <Grid item xs={10}>
                    <ListColsHeader
                        type="columns"
                        className={classes.listHeader}
                        columns={usersColumns}
                    />
                </Grid>
                <Grid item xs={2} className={classes.addButtonBox}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            className={classes.addButton}
                            startIcon={<AddIcon />}
                            onClick={handleAdd}
                        >
                            {t('add')}
                        </Button>
                    </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <NoData condition={usersData.length === 0} loading={loading} />
                    {
                        loading
                            ? [...Array(5)].map((_, index) => (
                                <Box mb={2} key={index}>
                                    <Skeleton height={62.5} variant="rect" animation="wave" className={classes.skeleton} />
                                </Box>
                            ))
                            : usersData.map( user => {
                                return (
                                    <UserCard
                                        key={user.id}
                                        user={user}
                                        openEdit={openEdit}
                                        handleDelete={handleDelete}
                                    />
                                )
                            })
                    }
                </Grid>
            </Grid>
            
            <UserDialog 
                open={dialogOpen}
                setOpen={setDialogOpen}
                userDialogConfig={userDialogConfig}
                setUserDialogConfig={setUserDialogConfig}
            />

            <NotifModal {...warnModal} />
            <NotifModal {...deleteModal} />
        </>
    );
    }

    export default UsersList;
