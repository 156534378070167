import { SET_ALL_USERS } from "../../constants";

const initialState = {
    users: []
}

export default (state = initialState, action) => {
    switch (action.type){
        case SET_ALL_USERS:
            return setAllUsers(state, action.payload);
        default:
            return state;
    }
}

const setAllUsers = (state, data) => {
    return {
        users: data
    };
}