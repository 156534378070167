import { validateEmail } from '../../../utils/validation';
import {
    getAllTaxType, postTaxType, putTaxType, deleteTaxType,
    getAllInvoicingEntity, postInvoicingEntity, putInvoicingEntity, deleteInvoicingEntity,
    getAllInvoicingClient, postInvoicingClient, putInvoicingClient, deleteInvoicingClient, 
    getAllProjects,
    getAllInvoiceAttachmentType,
    postInvoiceAttachmentType,
    putInvoiceAttachmentType,
    deleteInvoiceAttachmentType,
    getAllProjectType,
    getAllProjectStatus,
    postProject,
    putProject,
    deleteProject,
    postProjectStatus,
    putProjectStatus,
    deleteProjectStatus,
    postProjectType,
    putProjectType,
    deleteProjectType,
} from '../../redux/actions/rt';
import { deleteRtBankAccount, getAllRtBankAccounts, postRtBankAccount, updateBankAccount } from '../../redux/actions/rt/bankAccount';
import { deleteRtCurrency, getAllRtCurrencies, postRtCurrency } from '../../redux/actions/rt/currency';
import { deleteinvoiceType, getAllInvoiceType, postInvoiceType, putInvoiceType } from '../../redux/actions/rt/invoiceType';
import { deleteProjectClient, getAllProjectClient, postProjectClient, putProjectClient } from '../../redux/actions/rt/projectClient';

const defaultAddressFormat = ({name, address, postalCode, city, country}) => {
    let text = name;

    if(address) {
        text += "\n" + address;
    }

    if(postalCode) {
        text += "\n" + postalCode;
        if(city) text += " " + city;
        if(country) text += " " + country;
    } else if(city) {
        text += "\n" + city;
        if(country) text += " " + country;
    } else if(country) {
        text += "\n" + country;
    }

    return {"entityMap":{},"blocks":[{"key":"637gr","text": text,"type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};
};

const processPaymentDelay = (val) => {
    if(val <= 0){
        return null;  
    }else {
        return val; 
    }
};

export const RtablesDef = [
    {
        id: 0,
        name: 'bank_accounts',
        group: 'invoicing',
        pageable: false,
        searchable: true,
        editable:true,
        path: '/administration/reference-tables/bankAccount',
        columns: [{
            id: 0,
            name: 'label',
            apiName: 'label',
            width: 3,
            type: 'text',
            fullWidth: true,
            searchable: true,
        },{
            id: 1,
            name: 'currency',
            apiName: 'currency.code',
            apiBase: "currency",
            width: 3,
            type: 'select',
            searchable: true,
            config: {
                apiName: "code",
                storeName: "currencies",
                listAll: getAllRtCurrencies
            }
        },{
            id: 2,
            name: 'bank_id_label',
            apiName: 'bankIdLabel',
            width: 3,
            type: 'text',
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 3,
            name: 'bank_id',
            apiName: 'bankId',
            width: 3,
            type: 'text',
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 4,
            name: 'bank_code_label',
            apiName: 'bankCodeLabel',
            width: 3,
            type: 'text',
            collapse: true,
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 5,
            name: 'bank_code',
            apiName: 'bankCode',
            width: 3,
            type: 'text',
            collapse: true,
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 6,
            name: 'bank_name_label',
            apiName: 'bankNameLabel',
            width: 3,
            type: 'text',
            collapse: true,
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 7,
            name: 'bank_name',
            apiName: 'bankName',
            width: 3,
            type: 'text',
            collapse: true,
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 8,
            name: 'bank_address_label',
            apiName: 'bankAddressLabel',
            width: 3,
            type: 'text',
            collapse: true,
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        {
            id: 9,
            name: 'bank_address',
            apiName: 'bankAddress',
            width: 3,
            type: 'text',
            collapse: true,
            optional: true,
            fullWidth: true,
            searchable: true,
        },
        ],
        hasColor: false,
        storeName: 'bankAccounts',
        listAll: getAllRtBankAccounts,
        post: postRtBankAccount,
        put: updateBankAccount,
        delete: deleteRtBankAccount
    },
    {
        id: 1,
        name: 'currencies',
        group: 'invoicing',
        pageable: false,
        searchable: true,
        custom:true,
        path: '/administration/reference-tables/currencies',
        columns: [{
            id: 0,
            name: 'code',
            apiName: 'code',
            width: 3,
            type: 'text',
            fullWidth: true,
            searchable: true,
        },{
            id: 1,
            name: 'symbol',
            apiName: 'taxRates',
            width: 3,
            type: 'text',
            fullWidth: true,
            searchable: true,
        },{
            id: 2,
            name: 'exchangeRate',
            apiName: 'exchangeRate',
            width: 3,
            type: 'text',
            fullWidth: true,
            searchable: true,
        },
        {
            id: 3,
            name: 'default_value',
            apiName: 'isDefault',
            width: 3,
            type: 'radio',
        }
        ],
        hasColor: false,
        storeName: 'currencies',
        listAll: getAllRtCurrencies,
        post: postRtCurrency,
        delete: deleteRtCurrency
    },
    {
        id: 2,
        name: 'taxes',
        group: 'invoicing',
        editable: true,
        pageable: false,
        searchable: true,
        custom:true,
        path: '/administration/reference-tables/taxes',
        columns: [{
            id: 0,
            name: 'label',
            apiName: 'label',
            width: 2,
            type: 'text',
            fullWidth: true,
            searchable: true,
        },{
            id: 1,
            name: 'taxRates',
            apiName: 'taxRates',
            width: 4,
            type: 'text',
            fullWidth: true,
            searchable: true,
        },
        {
            id: 2,
            name: 'default_value',
            apiName: 'isDefaultValue',
            width: 2,
            type: 'select',
            config: {
                apiName: "value",
                storeName: "taxTypeRates",
            },
        },
        {
            id: 3,
            name: 'required',
            apiName: 'isRequired',
            width: 4,
            type: 'radio'
        }

        ],
        hasColor: false,
        storeName: 'taxTypes',
        listAll: getAllTaxType,
        post: postTaxType,
        put: putTaxType,
        delete: deleteTaxType
    },
    {
        id: 3,
        name: 'invoicingClients',
        group: 'invoicing',
        editable: true,
        pageable: true,
        searchable: true,
        actions: [
            {
                id: 0,
                name: 'format_address',
                apiName: "formattedAddress",
                defaultValue: defaultAddressFormat,
                type: 'textEditor',
                confirm: putInvoicingClient
            }
        ],
        path: '/administration/reference-tables/invoice-clients',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 3,
                type: 'text',
                fullWidth: true,
                searchable: true,
            },
            {
                id: 1,
                name: 'code',
                apiName: 'code',
                width: 2,
                type: 'text',
                fullWidth: true,
                searchable: true,
            },
            {
                id: 2,
                name: 'vat_number',
                apiName: 'vatNumber',
                width: 3,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,

            },
            {
                id: 3,
                name: 'city',
                apiName: 'city',
                width: 2,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 4,
                name: 'country',
                apiName: 'country',
                width: 2,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 5,
                name: 'address',
                apiName: 'address',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 6,
                name: 'postal_code',
                apiName: 'postalCode',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 7,
                name: 'siret',
                apiName: 'siret',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 8,
                name: 'payment_service_number',
                apiName: 'paymentServiceNumber',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 9,
                name: 'payment_delay',
                apiName: 'paymentDelay',
                collapse: true,
                type: 'number',
                reverseTransform: processPaymentDelay,
                fullWidth: true,
                optional: true,
                searchable: true,
                inputProps: {
                    min : 0
                }
            },
            {
                id: 10,
                name: "mailable",
                apiName: "mailable",
                type: "boolean",
                collapse: true,
                optional: true,
                fullWidth: true,
                searchable: true
            },
            {
                id: 11,
                name: "emails",
                apiName: "emails",
                type: "textList",
                validated: (val) => validateEmail(val),
                collapse: true,
                showError: true,
                optional: true,
                fullWidth: true,
                searchable: true
            },
            {
                id: 12,
                name: "cc_emails",
                apiName: "ccEmails",
                type: "textList",
                validated: (val) => validateEmail(val),
                collapse: true,
                optional: true,
                fullWidth: true
            },
            {
                id: 13,
                name: 'currency',
                apiName: 'currency.code',
                apiBase: "currency",
                collapse: true,
                width: 3,
                type: 'select',
                searchable: true,
                optional: true,
                config: {
                    apiName: "code",
                    storeName: "currencies",
                    listAll: getAllRtCurrencies
                }
            },
            {
                id: 14,
                name: 'digital',
                apiName: 'digital',
                collapse: true,
                type: 'boolean',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
        ],
        hasColor: false,
        storeName: 'invoicingClients',
        listAll: getAllInvoicingClient,
        post: postInvoicingClient,
        put: putInvoicingClient,
        delete: deleteInvoicingClient
    },
    {
        id: 4,
        name: 'invoicing_entities',
        group: 'invoicing',
        editable: true,
        pageable: true,
        searchable: true,
        actions: [
            {
                id: 0,
                name: 'format_address',
                apiName: "formattedAddress",
                defaultValue: defaultAddressFormat,
                type: 'textEditor',
                confirm: putInvoicingEntity
            }
        ],
        path: '/administration/reference-tables/invoice-entities',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 3,
                type: 'text',
                fullWidth: true,
                searchable: true,
            },
            {
                id: 1,
                name: 'code',
                apiName: 'code',
                width: 2,
                type: 'text',
                fullWidth: true,
                searchable: true,
            },
            {
                id: 2,
                name: 'vat_number',
                apiName: 'vatNumber',
                width: 3,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 3,
                name: 'city',
                apiName: 'city',
                width: 2,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 4,
                name: 'country',
                apiName: 'country',
                width: 2,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 5,
                name: 'address',
                apiName: 'address',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 6,
                name: 'postal_code',
                apiName: 'postalCode',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 7,
                name: 'siret',
                apiName: 'siret',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 8,
                name: 'payment_service_number',
                apiName: 'paymentServiceNumber',
                collapse: true,
                type: 'text',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
            {
                id: 9,
                name: 'payment_delay',
                apiName: 'paymentDelay',
                collapse: true,
                type: 'number',
                reverseTransform: processPaymentDelay,
                fullWidth: true,
                optional: true,
                searchable: true,
                inputProps: {
                    min : 0
                }
            },
            {
                id: 10,
                name: "mailable",
                apiName: "mailable",
                type: "boolean",
                collapse: true,
                optional: true,
                fullWidth: true,
                searchable: true
            },
            {
                id: 11,
                name: "emails",
                apiName: "emails",
                type: "textList",
                validated: (val) => validateEmail(val),
                collapse: true,
                showError: true,
                optional: true,
                fullWidth: true,
                searchable: true
            },
            {
                id: 12,
                name: "cc_emails",
                apiName: "ccEmails",
                type: "textList",
                validated: (val) => validateEmail(val),
                collapse: true,
                optional: true,
                fullWidth: true
            },
            {
                id: 13,
                name: 'currency',
                apiName: 'currency.code',
                apiBase: "currency",
                collapse: true,
                width: 3,
                type: 'select',
                searchable: true,
                optional: true,
                config: {
                    apiName: "code",
                    storeName: "currencies",
                    listAll: getAllRtCurrencies
                }
            },
            {
                id: 14,
                name: 'digital',
                apiName: 'digital',
                collapse: true,
                type: 'boolean',
                fullWidth: true,
                optional: true,
                searchable: true,
            },
        ],
        hasColor: false,
        storeName: 'invoicingEntities',
        listAll: getAllInvoicingEntity,
        post: postInvoicingEntity,
        put: putInvoicingEntity,
        delete: deleteInvoicingEntity
    },
    {
        id: 5,
        name: 'invoice_type',
        group: 'invoicing',
        editable: true,
        pageable: false,
        searchable: true,
        path: '/administration/reference-tables/invoice-types',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 3,
                type: 'text',
                searchable: true,
                style: {
                    textAlign: "left"
                }
            }
        ],
        hasColor: false,
        storeName: 'invoiceTypes',
        listAll: getAllInvoiceType,
        post: postInvoiceType,
        put: putInvoiceType,
        delete: deleteinvoiceType
    },
    {
        id: 6,
        name: 'invoice_attachment_types',
        group: 'invoicing',
        editable: true,
        pageable: false,
        searchable: true,
        path: '/administration/reference-tables/invoice-attachment-types',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 3,
                type: 'text',
                searchable: true,
                style: {
                    textAlign: "left"
                }
            }
        ],
        hasColor: false,
        storeName: 'invoiceAttachmentTypes',
        listAll: getAllInvoiceAttachmentType,
        post: postInvoiceAttachmentType,
        put: putInvoiceAttachmentType,
        delete: deleteInvoiceAttachmentType
    },
    {
        id: 7,
        name: 'projects',
        group: 'projects',
        editable: true,
        pageable: true,
        searchable: true,
        path: '/administration/reference-tables/projects',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 3,
                type: 'text',
                fullWidth: true,
                searchable: true,
                style: {
                    textAlign: "left"
                }
            },
            {
                id: 1,
                name: 'code',
                apiName: 'code',
                width: 2,
                type: 'text',
                searchable: true,
            },
            {
                id: 2,
                name: 'client',
                apiName: 'client.name',
                apiBase: "client",
                width: 3,
                type: 'select',
                searchable: true,
                optional: true,
                config: {
                    apiName: "name",
                    storeName: "projectClients",
                    listAll: getAllProjectClient
                }
            },
            {
                id: 3,
                name: 'status',
                apiName: 'status.name',
                apiBase: "status",
                width: 2,
                type: 'select',
                searchable: true,
                config: {
                    apiName: "name",
                    storeName: "projectStatus",
                    listAll: getAllProjectStatus
                }
            },
            {
                id: 4,
                name: 'type',
                apiName: 'type.name',
                apiBase: "type",
                width: 2,
                type: 'select',
                searchable: true,
                config: {
                    apiName: "name",
                    storeName: "projectType",
                    listAll: getAllProjectType
                }
            },
            {
                id: 5,
                name: 'payment_delay',
                apiName: 'paymentDelay',
                width: 2,
                type: 'number',
                reverseTransform: processPaymentDelay,
                optional:true,
                searchable: true,
                collapse: true,
                editable: true,
                inputProps: {
                    min : 0
                }
            }
        ],
        hasColor: false,
        storeName: 'projects',
        listAll: getAllProjects,
        post: postProject,
        put: putProject,
        delete: deleteProject
    },
    {
        id: 8,
        name: 'project_types',
        group: 'projects',
        editable: true,
        pageable: true,
        searchable: true,
        path: '/administration/reference-tables/project-types',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 6,
                type: 'text',
                searchable: true
            },
            {
                id: 1,
                name: 'code',
                apiName: 'code',
                width: 6,
                type: 'text',
                searchable: true
            }
        ],
        hasColor: false,
        storeName: 'projectType',
        listAll: getAllProjectType,
        post: postProjectType,
        put: putProjectType,
        delete: deleteProjectType
    },
    {
        id: 9,
        name: 'project_status',
        group: 'projects',
        editable: true,
        pageable: true,
        searchable: true,
        path: '/administration/reference-tables/project-status',
        columns: [
            {
                id: 0,
                name: 'name',
                apiName: 'name',
                width: 6,
                type: 'text',
                searchable: true
            },
            {
                id: 1,
                name: 'code',
                apiName: 'code',
                width: 6,
                type: 'text',
                searchable: true
            }
        ],
        hasColor: false,
        storeName: 'projectStatus',
        listAll: getAllProjectStatus,
        post: postProjectStatus,
        put: putProjectStatus,
        delete: deleteProjectStatus
    }
];